import React, { useContext, useRef, useState } from "react";
import { Grid, TextField } from "@mui/material";
import CustomSelect from "../../formikInputs/CustomSelect";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../formikInputs/CustomTextField";
import UserSelectFromGroup from "./formikComponentsForSearch/UserSelectFromGroup";
import { FormikContext } from "formik";
import axios from "axios";
import LoginContext from "../../../context/LoginContext";

export default function CustFormLite(props) {
  const { t, i18n } = useTranslation();
  let readMode = "";
  const [zFlag, setZFlag] = useState(true);
  const zipcodeRef = useRef();
  const { userRole } = useContext(LoginContext);
  //const [value, setValue] = React.useState([null, null]);

  const variableListConsultation = [
    { value: 1, name: "保険" },
    { value: 2, name: "資産運用について" },
    { value: 3, name: "老後資金について" },
    { value: 4, name: "教育費" },
    { value: 5, name: "相続・贈与" },
  ];

  //do not change these values.

  const variableListContactTime = [
    { value: 1, name: "平日：午前中（10-12時）" },
    { value: 2, name: "平日：午後（12-18時）" },
    { value: 3, name: "平日：夕方以降（18時以降）" },
    { value: 4, name: "土日：午前中（10-12時）" },
    { value: 5, name: "土日：午後（12-18時）" },
    { value: 6, name: "土日：夕方以降（18時以降）" },
  ];

  return (
    <>
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            data={{
              name: "custData.customerLastName",
              label: `${t("Last Name")}`,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            data={{
              name: "custData.customerFirstName",
              label: `${t("First Name")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: {
                height: "55px",
              },
            }}
            data={{
              name: "custData.customerBirthday",
              label: `${t("Date of Birth~")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: {
                height: "55px",
              },
            }}
            data={{
              name: "custData.customerBirthdayEnd",
              label: `${t("~Date of Birth")}`,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            data={{
              name: "custData.customerLastRuby",
              label: `${t("Last Ruby")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            data={{
              name: "custData.customerFirstRuby",
              label: `${t("First Ruby")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="number"
            InputLabelProps={{ shrink: true }}
            data={{
              name: "custData.customerAge",
              label: `${t("Age~")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="number"
            InputLabelProps={{ shrink: true }}
            data={{
              name: "custData.customerAgeEnd",
              label: `${t("~Age")}`,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            mode={readMode}
            type="number"
            data={{
              name: "custData.customerId",
              label: `${t("Customer ID")}`,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <CustomTextField
            mode={readMode}
            type="email"
            data={{ name: "custData.customerEmail", label: `${t("Email")}` }}
          />
        </Grid>

        <Grid item xs={4}>
          <CustomSelect
            mode={readMode}
            data={{
              name: "custData.customerSex",
              label: `${t("Sex")}`,
              list: [
                { value: "Male", name: "Male" },
                { value: "Female", name: "Female" },
                { value: "Other", name: "Other" },
              ],
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <CustomSelect
            mode={readMode}
            data={{
              name: "custData.storeUsed",
              label: `${t("Stores Used")}`,
              list: [
                {
                  value: "mobile store",
                  name: `${t("Mobile store")}`,
                }, 
                // {
                //   value: "town store",
                //   name: `${t("イオンモバイル△△△町店")}`,
                // },
                {
                  value: "itabashi mobile store",
                  name: `${t("Itabashi store")}`,
                },
                {
                  value: "yamato tsuruma store",
                  name: `${t("Aeon Mobile Yamato Tsuruma Store")}`,
                },
                {
                  value: "yachiyo midorigaoka store",
                  name: `${t("Aeon Mobile Yachiyo Midorigaoka Store")}`,
                },
                {
                  value: "murayama mobile store",
                  name: `${t("Aeon Murayama Mobile Store")}`,
                },
              ],
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomSelect
            mode={readMode}
            data={{
              name: "custData.customerMaritalStatus",
              label: `${t("Customer Marital Status")}`,
              list: [
                {
                  value: true,
                  name: `${t("あり")}`,
                },
                {
                  value: false,
                  name: `${t("なし")}`,
                },
              ],
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomSelect
            mode={readMode}
            data={{
              name: "custData.customerChildren",
              label: `${t("Customer Children")}`,
              list: [
                {
                  value: true,
                  name: `${t("あり")}`,
                },
                {
                  value: false,
                  name: `${t("なし")}`,
                },
              ],
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomSelect
            mode={readMode}
            data={{
              name: "custData.householdIncome",
              label: `${t("Household Income")}`,
              list: [
                {
                  value: "300",
                  name: `${t("〜300万円")}`,
                },
                {
                  value: "500",
                  name: `${t("300〜500万円")}`,
                },
                {
                  value: "700",
                  name: `${t("500〜700万円")}`,
                },
                {
                  value: "1000",
                  name: `${t("700〜1000万円")}`,
                },
                {
                  value: "9999",
                  name: `${t("1000万円〜")}`,
                },
              ],
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <CustomSelect
            mode={readMode}
            data={{
              name: `custData.contentsOfConsultation`,
              label: t("Contents Of Consultation"),
              list: variableListConsultation,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <CustomSelect
            mode={readMode}
            data={{
              name: `custData.preferredContactTime`,
              label: t("Preferred Contact Time"),
              list: variableListContactTime,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <CustomTextField
            mode={readMode}
            //type="number"
            inputRef={zipcodeRef}
            data={{
              name: "custData.customerZipCode",
              label: `${t("ZipCode")}`,
            }}
          />
        </Grid>
        <FormikContext.Consumer>
          {(formikContext) => {
            //console.log(formikContext, "fffffff");
            const zipcode = formikContext.values.custData.customerZipCode;
            if (zFlag) {
              const fetchZipcodeData = async () => {
                const baseUrl = "https://zipcloud.ibsnet.co.jp/api/search";
                const url = `${baseUrl}?zipcode=${zipcode}`;
                try {
                  const response = await axios.get(url);
                  console.log(response, "zipcode API response");
                  if (response.data.status === 200 && response.data.results) {
                    console.log(response.data.results, "success result");
                    formikContext.setFieldValue(
                      "custData.customerAddress1",
                      response.data.results[0].address1
                    );
                    formikContext.setFieldValue(
                      "custData.customerAddress2",
                      response.data.results[0].address2
                    );
                    formikContext.setFieldValue(
                      "custData.customerAddress3",
                      response.data.results[0].address3
                    );
                  } else {
                    console.log("error:", response.data.message);
                  }
                } catch (error) {
                  console.error("Error:", error.message);
                }
                return null;
              };
              if (zipcode.length === 7 && zipcode.indexOf("-") === -1) {
                setZFlag(false);
                fetchZipcodeData();
                setTimeout(() => {
                  zipcodeRef.current.focus(); // Maintain focus on the ZipCode input field
                }, 0);
              } else if (zipcode.length === 8 && zipcode.indexOf("-") !== -1) {
                setZFlag(false);
                fetchZipcodeData();
                setTimeout(() => {
                  zipcodeRef.current.focus(); // Maintain focus on the ZipCode input field
                }, 0);
              }
            } else {
              if (
                (zipcode.indexOf("-") === -1 && zipcode.length < 7) ||
                (zipcode.indexOf("-") !== -1 && zipcode.length < 8)
              ) {
                setZFlag(true);
                setTimeout(() => {
                  zipcodeRef.current.focus(); // Maintain focus on the ZipCode input field
                }, 0);
              }
            }

            return (
              <>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="outlined-controlled"
                    label={t("Address 1")}
                    value={formikContext.values.custData.customerAddress1}
                    onChange={(event) => {
                      formikContext.setFieldValue(
                        "custData.customerAddress1",
                        event.target.value
                      );
                    }}
                  />
                  {/* <CustomTextField
                    mode={readMode}
                    data={{
                      name: "custData.customerAddress1",
                      label: `${t("Address 1")}`,
                    }}
                  /> */}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="outlined-controlled"
                    label={t("Address 2")}
                    value={formikContext.values.custData.customerAddress2}
                    onChange={(event) => {
                      formikContext.setFieldValue(
                        "custData.customerAddress2",
                        event.target.value
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="outlined-controlled"
                    label={t("Address 3")}
                    value={formikContext.values.custData.customerAddress3}
                    onChange={(event) => {
                      formikContext.setFieldValue(
                        "custData.customerAddress3",
                        event.target.value
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="outlined-controlled"
                    label={t("Address 4")}
                    value={formikContext.values.custData.customerAddress4}
                    onChange={(event) => {
                      formikContext.setFieldValue(
                        "custData.customerAddress4",
                        event.target.value
                      );
                    }}
                  />
                </Grid>
              </>
            );
          }}
        </FormikContext.Consumer>
        <Grid item xs={4}>
          <CustomTextField
            mode={readMode}
            data={{
              name: "custData.customerBusinessType",
              label: `${t("Profession")}`,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <UserSelectFromGroup
            mode={readMode || userRole == 1 || userRole == 2}
            name="custData.customerFirstUserId"
            label={t("First User Name")}
            groupId={props.groupId || 0}
            //originalGroup={props.originalGroup || 0}
            typeFlag="search"
          />
        </Grid>
        <Grid item xs={3}>
          <UserSelectFromGroup
            mode={readMode || userRole == 1 || userRole == 2}
            name="custData.customerSecondUserId"
            label={t("Second User Name")}
            groupId={props.groupId || 0}
            //originalGroup={props.originalGroup || 0}
            typeFlag="search"
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: {
                height: "55px",
              },
            }}
            data={{
              name: "custData.customerModifyDateTime",
              label: `${t("Last Updated Start")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: {
                height: "55px",
              },
            }}
            data={{
              name: "custData.customerModifyDateTimeEnd",
              label: `${t("Last Updated End")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: {
                height: "55px",
              },
            }}
            data={{
              name: "custData.customerRegisterDateTime",
              label: `${t("Customer Link Date From")}`,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            mode={readMode}
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: {
                height: "55px",
              },
            }}
            data={{
              name: "custData.customerRegisterDateTimeEnd",
              label: `${t("Customer Link Date To")}`,
            }}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <CustomTextField
            mode={readMode}
            multiline
            minRows={3}
            data={{ name: "custData.customerMemo1", label: `${t("Memo 1")}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            mode={readMode}
            multiline
            minRows={3}
            data={{ name: "custData.customerMemo2", label: `${t("Memo 2")}` }}
          />
        </Grid>
      </Grid>
    </>
  );
}

/* <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={4}>
        <CustomTextField mode=""  data={{ name: "custData.custData.customerLastName", label: "Last Name" }} />
        </Grid>
        <Grid item xs={4}>
        <CustomTextField mode=""  data={{ name: "custData.custData.customerFirstName", label: "First Name" }} />
        </Grid>
        <Grid item xs={4}>
        <CustomSelect
                mode=""
                data={{
                  name: "custData.customerSex",
                  label: `${t("Sex")}`,
                  list: [
                    { value: "Male", name: "custData.Male" },
                    { value: "Female", name: "custData.Female" },
                    { value: "Other", name: "custData.Other" },
                  ],
                }}
              />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField mode=""
             data={{ name: "custData.custData.customerLastRuby", label: "Last Kana Name" }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField mode=""
             data={{ name: "custData.custData.customerFirstRuby", label: "First Kana Name" }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField mode=""
             data={{ name: "custData.custData.customerBirthday", label: "Birthday" }}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTextField mode=""
            
            data={{ name: "custData.custData.customerEmail", label: "Email" }}
            type="email"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField mode=""
          
            data={{ name: "custData.custData.customerAddress1", label: "Address 1" }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField mode=""
          
            data={{ name: "custData.custData.customerAddress2", label: "Address 2" }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField mode=""
          
            data={{ name: "custData.custData.customerAddress3", label: "Address 3" }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField mode=""
          
            data={{ name: "custData.custData.customerAddress4", label: "Address 4" }}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTextField mode=""
            
            data={{ name: "custData.custData.customerZipCode", label: "Zipcode" }}
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTextField mode=""
          
            data={{ name: "custData.custData.customerBusinessType", label: "Profession" }}
          />
        </Grid>
        <Grid item xs={6}>
              <CustomTextField mode=""
                
                multiline
                minRows={3}
                data={{ name: "custData.customerMemo1", label: "Memo 1" }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField mode=""
                
                multiline
                minRows={3}
                data={{ name: "custData.customerMemo2", label: "Memo 2" }}
              />
            </Grid>
      </Grid> */
