import {
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik, useFormikContext, FormikContext } from "formik";
import React, { useContext, useEffect } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import SelectWithDelete from "../commonComponents/SelectWithDelete";
import CustomMultiSelectCheck from "../formikInputs/CustomMultiSelectCheck";
import CustomTextField from "../formikInputs/CustomTextField";
import GroupSelect from "../formikInputs/GroupSelect";
import CategoryMultiSelect from "./CategoryMultiSelect";
import { saveAs } from "file-saver";
import { v4 as uuid } from "uuid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ListChipItem from "./ListChipItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function Export() {
  const { t } = useTranslation();
  const customerColumnTemplate = [
    { value: "CUScustomerId", name: `${t("Customer ID")}` },
    { value: "CUScustomerBirthday", name: `${t("Customer Birthday")}` },
    { value: "CUScustomerLastName", name: `${t("Customer Last Name")}` },
    { value: "CUScustomerFirstName", name: `${t("Customer First Name")}` },
    { value: "CUScustomerLastRuby", name: `${t("Customer Last Ruby")}` },
    { value: "CUScustomerFirstRuby", name: `${t("Customer First Ruby")}` },
    { value: "CUScustomerZipCode", name: `${t("Customer ZipCode")}` },
    { value: "CUScustomerAddress1", name: `${t("Customer Address 1")}` },
    { value: "CUScustomerAddress2", name: `${t("Customer Address 2")}` },
    { value: "CUScustomerAddress3", name: `${t("Customer Address 3")}` },
    { value: "CUScustomerAddress4", name: `${t("Customer Address 4")}` },
    { value: "CUScustomerEmail", name: `${t("Customer Email")}` },
    { value: "CUScustomerSex", name: `${t("Customer Sex")}` },
    { value: "CUScustomerFirstUserId", name: `${t("Customer FirstUserId")}` },
    { value: "CUScustomerSecondUserId", name: `${t("Customer SecondUserId")}` },
    { value: "CUScustomerMemo1", name: `${t("Customer Memo 1")}` },
    { value: "CUScustomerMemo2", name: `${t("Customer Memo 2")}` },
    { value: "CUScustomerBusinessType", name: `${t("Customer BusinessType")}` },
    { value: "CUScustomerRegisterDateTime", name: `${t("Customer Register Date Time")}` },
    // { value: "CUScustomerAge", name: `${t("Customer Age")}` },
    { value: "CUSproject.name", name: `${t("Customer Project")}` },
    { value: "CUStelephone[0].telNum", name: `${t("Customer Telephone 1")}` },
    { value: "CUStelephone[1].telNum", name: `${t("Customer Telephone 2")}` },
    { value: "CUStelephone[2].telNum", name: `${t("Customer Telephone 3")}` },
    { value: "CUStelephone[3].telNum", name: `${t("Customer Telephone 4")}` },
    { value: "CUStelephone[4].telNum", name: `${t("Customer Telephone 5")}` },
    { value: "CUStelephone[5].telNum", name: `${t("Customer Telephone 6")}` },
    { value: "CUStelephone[6].telNum", name: `${t("Customer Telephone 7")}` },
  ];

  const topicColumnTemplate = [
    { value: "TOPtitle", name: `${t("Title")}` },
    { value: "TOPphase", name: `${t("Phase")}` },
    { value: "TOPcaller", name: `${t("Caller")}` },
    { value: "TOPcallFlag.name", name: `${t("Call Flag")}` },
    { value: "TOPmodifyUserId", name: `${t("Topic Last Updated By")}` },
    { value: "TOPmodifyDateTime", name: `${t("Topic Last Updated")}` },
    { value: "TOPmemo", name: `${t("Memo")}` },
    { value: "TICid", name: `${t("Ticket ID")}` },
    { value: "TICstartTime", name: `${t("Ticket -> startTime")}` },
    { value: "TICendTime", name: `${t("Ticket -> endTime")}` },
    { value: "TICanswerTime", name: `${t("Ticket -> answerTime")}` },
  ];

  const axiosFetch = AxiosFetch();
  const location = useLocation();
  const searchObject = location.state.data; //search parameters
  const navigate = useNavigate();
  const context = useContext(LoginContext);
  const formRef = useRef(null);
  useEffect(() => {
    context.setPageTitle("Customer Export");
  }, []);
  async function getData() {
    try {
      const response = await axiosFetch.get(`/exportFormat`);
      console.log(response.data, "export format list response");
      setFormatList(response.data);
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [formatList, setFormatList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  //below states for format
  const [selected, setSelected] = useState("None");

  function convertValueToName(text, formatFlag) {
    const obj = customerColumnTemplate.find((data) => data.value === text);
    if (obj) return obj.name;
    const obj2 = topicColumnTemplate.find((data) => data.value === text);
    if (obj2) return obj2.name;
    const obj3 = categoryList.find((data) => data.value === text);
    if (obj3) return obj3.name;
    return text;
  }

  const initialValues = {
    groupId: 0,
    selectColumns: [],
    itemIdList: [],
    topicColumns: [],
    orderList: [],
    selectedformat: "None",
    formatName: "",
  };

  const FormObserver = useCallback(() => {
    const { values, setFieldValue } = useFormikContext();
    //console.log(values, "live Form values");
    useEffect(() => {
      //setFieldValue("itemIdList", []);

      setGroupId(values.groupId);
    }, [values.groupId]);
    useEffect(() => {
      const arr = values.selectColumns.concat(
        values.topicColumns,
        values.itemIdList
      );
      const prev = values.orderList;
      var newArr = [];
      prev.forEach((data, index) => {
        if (arr.includes(data)) newArr.push(data);
      });
      arr.forEach((data, index) => {
        if (!prev.includes(data)) newArr.push(data);
      });

      setFieldValue("orderList", newArr);
    }, [values.selectColumns, values.topicColumns, values.itemIdList]);
    return null;
  }, []);

  function downloadBlob(content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
  }
  const handleSelected = (e) => {
    setSelected(e);
  };
  const handleSubmit = async (value) => {
    console.log(value, "valuee");
    setExportLoading(true);
    var APIvalues = {};
    APIvalues = {
      customer: searchObject.customer,
      categoryData: searchObject.categoryData,
      orderList: value.orderList,
      groupId: value.groupId,
    };

    console.log(APIvalues, "API values");
    
    let filename;
    if(selected=='None'){
      filename='export.csv'
    }
    else{
   filename = selected.endsWith(".csv") ? selected : selected + ".csv";
    }
    try {
      const response = await axiosFetch.post(`/export`, APIvalues, {
        responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], {
        type: "text/csv;charset=Shift_JIS",
      });
      saveAs(blob, filename);
    } catch (err) {
      console.log(err.response.data, "err");
      alert(JSON.stringify(err.response.data, null, 4));
    } finally {
      setExportLoading(false);
    }
  };

  const handleSaveFormat = async () => {
    const values = formRef.current.values;
    console.log(values, "vvvvv");
    //separate ticket and topic array from  topicColumns array
    let ticketArr = [];
    let topicArr = [...values.topicColumns];
    let topicArrWithoutTicket = [];
    topicArr.forEach((data, i) => {
      if (data.includes("ticket.")) {
        let index = data.indexOf("ticket.");
        let result = data.substring(0, index) + data.substring(index + 7);
        ticketArr.push(result);
      } else {
        topicArrWithoutTicket.push(data);
      }
    });
    const arr = {
      orderList: values.orderList,
      groupId: values.groupId,
    };
    const APIvalues = {
      name: values.formatName,
      value: JSON.stringify(arr),
    };
    console.log(APIvalues, "APIvalues");
    try {
      const custResponse = await axiosFetch.post(
        "/exportFormat",
        JSON.stringify(APIvalues)
      );
      getData();
      console.log(custResponse, "response");
    } catch (error) {
      alert(error);
      console.log(error);
    }
    setOpenDialog(false);
  };

  const handleFormatDelete = async () => {
    let obj = formatList.find((o) => o.name === selected);
    //console.log(obj,'objj');
    try {
      const response = await axiosFetch.delete(`/exportFormat/${obj.id}`);
      console.log(response, "response format delete");
      setFormatList((current) => current.filter((e) => e.id !== obj.id));
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
    } catch (error) {
      //alert(error);
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error);
    }
  };
  const moveFinalOrderListItem = useCallback(
    (dragIndex, hoverIndex, setFieldValue, orderList) => {
      const dragItem = orderList[dragIndex];
      const hoverItem = orderList[hoverIndex];
      const updatedFinalOrderList = [...orderList];
      updatedFinalOrderList[dragIndex] = hoverItem;
      updatedFinalOrderList[hoverIndex] = dragItem;

      setFieldValue("orderList", updatedFinalOrderList);
    },
    []
  );
  useEffect(() => {
    var temp = formatList.find((o) => o.name === selected);
    console.log(formRef, "ffffff");
    if (temp) {
      const { orderList, groupId } = JSON.parse(temp.value);
      //setSelectedFormatOrderList(orderList);
      formRef.current.setFieldValue("orderList", orderList);
      formRef.current.setFieldValue("groupId", groupId || 0);

      //separate orderList
      var selectColumns = [];
      var topicColumns = [];
      var itemIdList = [];
      orderList.forEach((data, index) => {
        if (data.substring(0, 3) === "CUS") {
          selectColumns.push(data);
        } else if (data.substring(0, 3) === "TOP") {
          topicColumns.push(data);
        } else if (data.substring(0, 3) === "TIC") {
          topicColumns.push(data);
        } else if (data.substring(0, 3) === "CAT") {
          itemIdList.push(data);
        } else {
          alert("some bug");
        }
      }, []);
      formRef.current.setFieldValue("selectColumns", selectColumns);
      formRef.current.setFieldValue("topicColumns", topicColumns);
      formRef.current.setFieldValue("itemIdList", itemIdList);
    }
  }, [selected]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
        innerRef={formRef}
      >
        <Form id="form">
          <FormObserver />
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            fullWidth
            maxWidth="md"
          >
            <Box sx={{ px: "2%", py: "2%", alignItems: "center" }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <CustomTextField
                    data={{ name: "formatName", label: "Enter Name" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={handleSaveFormat}
                  >
                    {t("Save")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
          <Box
            sx={{
              alignItems: "center",
              px: "8%",
              py: "5%",
              maxWidth: "100vw",
              // background: "red",
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!exportLoading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  justifyContent={"center"}
                  display="flex"
                >
                  <Grid item xs={12}>
                    <div>
                      {t("Saved Formats")}
                      <Tooltip
                        title={t("Choose from already saved output formats")}
                        fontSize="small"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </div>
                    <SelectWithDelete
                      list={formatList}
                      label="format label"
                      selected={selected}
                      handleSelected={(e) => {
                        handleSelected(e);
                      }}
                      handleSelectedDelete={handleFormatDelete}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupSelect
                      name="groupId"
                      tooltipProp={t("Required selection. Select a group")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "selectColumns",
                        label: `${t("Customer Information")}`,
                        list: customerColumnTemplate,
                      }}
                      selectAllFlag={true}
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <CategoryMultiSelect
                      name="itemIdList"
                      groupId={groupId}
                      setList={(list) => {
                        setCategoryList(list);
                      }}
                      formValuesLive={formRef}
                      
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <Tooltip
                      fontSize={"small"}
                      title={t(
                        "This column contains customized category information. Items in categories that belong to the selected group are displayed<"
                      )}
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={11.5}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "topicColumns",
                        label: `${t("Topic Columns")}`,
                        list: topicColumnTemplate,
                      }}
                      selectAllFlag={true}
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <Tooltip
                      fontSize="small"
                      title={t("Specify columns related to call topics")}
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t("Edit Output Order")}
                      <Tooltip
                        title={t(
                          "Specify the column order when outputting. You can change the order by dragging and dropping"
                        )}
                        fontSize='small'
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Typography>
                    <DndProvider backend={HTML5Backend}>
                      <FormikContext.Consumer>
                        {(context) => {
                          return (
                            <Box
                              border={1}
                              borderColor={"#c5c5c5"}
                              padding={2}
                              display={"flex"}
                              maxWidth={"100%"}
                              flexWrap={"wrap"}
                            >
                              {context.values.orderList.map((data, index) => (
                                <ListChipItem
                                  key={data} //or uuid()
                                  index={index}
                                  text={convertValueToName(data, false)}
                                  moveListItem={(dragIndex, hoverIndex) =>
                                    moveFinalOrderListItem(
                                      dragIndex,
                                      hoverIndex,
                                      context.setFieldValue,
                                      context.values.orderList
                                    )
                                  }
                                />
                              ))}
                            </Box>
                          );
                        }}
                      </FormikContext.Consumer>
                    </DndProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<FileDownloadIcon />}
                      // onClick={() => {
                      //   submitAction = "export";
                      // }}
                    >
                      {t("Export")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      size="large"
                      fullWidth
                      //startIcon={<SaveIcon />}
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                      sx={{
                        display: "inline-flex",
                        paddingLeft: "15%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <SaveIcon /> {t("Save As Format")}
                      </span>
                      <Tooltip
                        title={t(
                          "Save the displayed contents, from group to output order, as a new output format"
                        )}
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </>
  );
}
function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
